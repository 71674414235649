import "@fontsource/inter";
import { Box } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import AttachedDocumentModal from "apps/pos/AddNewCustomer/AttachedDocumentModal/AttachedDocumentModal";
import MaskInput from "apps/pos/Common/maskInput/maskInput";
import {
  Container,
  PosButton,
  PosIconButton,
  PosImage,
  PosInput,
  PosPageHeading,
  PosSelect,
} from "apps/pos/Common/uiCore";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import { ErrorMessage } from "apps/pos/formik/errorMessage";
import AddDocumentModal from "apps/pos/pages/customerInfo/Documents/addDocument/AddDocumentModal";
import { Button } from "components";
import SelectShippingAddressNames from "./SelectShippingAddressNames";

import {
  addressTextForm,
  facilityDetails,
  facilityForm,
  facilityTab,
} from "apps/pos/constants";
import { AddAddressSchema } from "apps/pos/formik/validationSchema";
import { title, zipRegex } from "constants/constant";
import { documentByModule } from "constants/enum";
import {
  AddIcon,
  ArrowBackRoundedIcon,
  ArrowUpwardIcon,
  CheckIcon,
  ClearIcon,
  SearchIcon,
  ShoppingBagIcon,
} from "constants/icons";
import images from "constants/images";
import { StateDataProps, UploadedDocumentItemProp } from "constants/interfaces";
import { Tabs } from "enums";
import { RootState, ShippingDrawerProps } from "models";
import { keys } from "utils/helper";
import {
  blockInvalidChar,
  blockInvalidCharForPhone,
  changestringVal,
  getStringifyData,
  phoneNumberFormatNew,
  scrollOnContent,
} from "utils/utils";

import {
  examptShipTolocationData,
  recipientInfoSwitchInfoData,
  setPosFlowData,
  setShipToAddress,
  setStorePickedUpAddress,
  setToPickUp,
  shippingAddressSelectedData,
} from "sagas/persistReducer/actions";
import {
  addShippingFacilitiesClear,
  addShippingFacilitiesRequest,
  arrowDownIndex,
  arrowUpIndex,
  getShippingFacilitiesRequest,
  getShiptoFacilitiesRequest,
  getStatesRequest,
  getTabShip,
  resetNewAddress,
} from "sagas/pos/actions";

interface QtyInStockProps {
  qtyInStock: number;
}

const SelectShippingAddress = ({
  setPickUpSelected,
  state,
  setState,
  setShipToByFacilityId,
  drawerRef,
  onCloseDrawer,
  setPaymentMethodWrapper,
  isPaymentMethodWrapper = true,
  setEscapePress,
  escapePress = false,
  openFromHeader = false
}) => {
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [loader, setLoader] = useState(false);
  const [shippingData, setShippingData] = useState<any>([]);
  const [statesData, setStatesData] = useState<any>([]);
  const [facilityName, setFacilityName] = useState("");
  const [step0Errors, setStep0Errors] = useState(false);
  const [step1Errors, setStep1Errors] = useState(false);
  const [facilityType] = useState("other");
  const [stateName, setStateName] = useState("");
  const [stateNameError, setStateNameError] = useState(false);
  const [enterPress, setEnterPress] = useState(false);
  const [extCode, setExtCode] = useState("");
  const [searchString, setSearchString] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [shipToTemp, setShipToTemp] = useState<any>({});
  const [showShipToLocation, setShowShipToLocation] = useState<any>({
    index: 1,
    isTrue: false,
  });
  const [shipToSelectedId, setShipToSelectId] = useState();
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);
  const [taxOpen, setTaxOpen] = useState(false);
  const [openLocationModel, setOpenLocationModel] = useState(false);
  const [newAddress, setnewAddress] = useState(false);
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const searchBuildingRef = useRef<any>();
  const {
    getTabShipping,
    shiptoFacilities,
    shippingFacilities,
    addShippingFacilities,
    getStatesData,
    addShippingFacilitiesSuccess,
    addShippingFacilitiesError,
    selectedIndex,
    isLoading,
    getIsShipToFacilitiesSuccess,
  } = useSelector<RootState, ShippingDrawerProps>((state) => state.pos);
  const { posFlowData, loadCartDetails, shippingAddressSelected, storePickedAddress } = useSelector<
    RootState,
    any
  >((state) => state.persistReducer);
  const [switchToPicked, setSwitchToPicked] = useState([]);

  const isGenericUser = useMemo(() => {
    return posFlowData?.customer ? posFlowData?.customer.isPosGeneric : false;
  }, [posFlowData]);
  const { stockWarehouseId } = loadCartDetails || {}
  const shippingRef = useRef(null);
  const { customer, pickOrderQuantity, pickQuantityArr, isCartAvailable } =
    posFlowData || {};
  const { shipping, cautionIcon, whiteUploadIcon } = images;
  const { recipient } = shipToTemp;
  const { street } = recipient || {};
  const { continueToPayment } = title;

  useEffect(() => {
    !getStatesData?.length && dispatch(getStatesRequest());
    if (!shippingFacilities?.length) {
      const { customer: loadCartDetailsCustomer } = loadCartDetails || {}
      dispatch(
        getShippingFacilitiesRequest(
          customer?.personId || (isCartAvailable
            ? loadCartDetailsCustomer?.customerId
            : customer?.customerId)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(shippingFacilities)) {
      const currentPickedAddress = shippingFacilities?.filter((address) => address?.stockWarehouseId === stockWarehouseId);
      currentPickedAddress && setSwitchToPicked(currentPickedAddress);
    }
  }, [shippingFacilities]);

  useEffect(() => {
    if ((switchToPicked.length > 0 && !storePickedAddress)) {
      dispatch(
        getShiptoFacilitiesRequest(
          switchToPicked[0]?.buildingID
        )
      );
    }
  }, [storePickedAddress, switchToPicked])

  useEffect(() => {
    if (shiptoFacilities?.isStorePickup) {
      dispatch(setStorePickedUpAddress(shiptoFacilities))
    }
  }, [shiptoFacilities])

  useEffect(() => {
    const data = {
      ...posFlowData,
      customerInfoUpdated: true,
    };
    setShipToTemp(shiptoFacilities);
    dispatch(setPosFlowData(data));
    const { facilityId: facilityIdFromShipto } = shiptoFacilities || {};
    const { facilityId: facilityIdFromPosFlow } = posFlowData?.shiptoFacilities || {};

    if (newAddress && !isLoading && getIsShipToFacilitiesSuccess && facilityIdFromShipto) {
      const shipData = {
        ...posFlowData,
        shiptoFacilities,
      };
      dispatch(setPosFlowData(shipData));
      setLoader(false);
      setPickUpSelected(false);
      setPaymentMethodWrapper();
      if ((facilityIdFromShipto !== facilityIdFromPosFlow) && facilityIdFromShipto !== undefined) {
        onCloseDrawer();
      }
    }
  }, [shiptoFacilities, newAddress, isLoading, getIsShipToFacilitiesSuccess]);

  useEffect(() => {
    if (!searchString) {
      setSearchData(shippingData);
    }
  }, [searchString]);

  useEffect(() => {
    if (Array.isArray(shippingFacilities)) {
      const filteredData = shippingFacilities?.filter(item => item.isStorePickup === false);

      const isStorePickupTrue = shippingFacilities?.filter(item => item.isStorePickup === true);

      const sortedData = [
        ...isStorePickupTrue,
        ...filteredData.sort((a, b) => b.numberOfOrders - a.numberOfOrders)
      ];

      setShippingData(sortedData);
    }
  }, [shippingFacilities]);

  useEffect(() => {
    setStatesData(getStatesData);
  }, [getStatesData]);

  useEffect(() => {
    if (addShippingFacilitiesSuccess) {
      dispatch(getShiptoFacilitiesRequest(addShippingFacilities?.data));
      const data = {
        ...posFlowData,
        pickOrderQuantity: [],
        pickQuantityArr: [],
        finalQtyArrayInfoData: [],
        addDocument: [],
      };
      dispatch(setPosFlowData(data));
      const { customer, isCartAvailable } = posFlowData || {}
      const { customer: loadCartDetailsCustomer } = loadCartDetails || {}
      dispatch(
        getShippingFacilitiesRequest(
          customer?.personId || (isCartAvailable
            ? loadCartDetailsCustomer?.customerId
            : customer?.customerId)
        )
      );
      setnewAddress(true);
      const { data: buildingId } = addShippingFacilities;

      setTimeout(() => {
        setShipToByFacilityId(buildingId);
        dispatch(setToPickUp(false));
        dispatch(setShipToAddress(true));
        dispatch(addShippingFacilitiesClear());
        dispatch(shippingAddressSelectedData(true))
        setState({ ...state, right: false });
        // setPaymentMethodWrapper()
      }, 1500);
    }
    if (addShippingFacilitiesError) {
      setLoader(false);
    }
  }, [addShippingFacilitiesSuccess, addShippingFacilitiesError]);
  //TODO: will remove any later
  const rowRef = useRef<FormikHelpers<any>>();

  const scrollToSetting = (selectedIndex) => {
    const elementId = document?.getElementById(`rowRef${selectedIndex}`);

    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end",
    });
  };
  const streetName = recipient?.street || "";

  useEffect(() => {
    if (downPress) {
      const dataLength =
        shippingData?.length && searchData?.length
          ? searchData?.length
          : shippingData?.length && !searchString
            ? shippingData.length
            : 20;
      scrollToSetting(selectedIndex);
      dispatch(arrowDownIndex(dataLength));
      setDownPress(false);
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedIndex > -1) {
        scrollToSetting(selectedIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  const [isAddressOpen, setIsAddressOpen] = useState<boolean>(true);

  useEffect(() => {
    if (isAddressOpen) {
      dispatch(getTabShip(Tabs.tabIndexZero));
      setIsAddressOpen(false);
    }
  }, [isAddressOpen]);

  useEffect(() => {
    if (escapePress) {
      setEscapePress(false)
      handleGoBack()
    }
  }, [escapePress])

  function handleKeyPress(e) {
    const { keyCode } = e;
    const { p, a, enter, downarrow, uparrow } = keys;
    const isButtonFocused = document.activeElement.tagName.toLowerCase() === 'button'
    if (
      document?.activeElement !== searchBuildingRef.current &&
      drawerRef.current
    ) {
      if (keyCode === p && shippingAddressSelected) {
        // Might need
        switchToPickup()
      } else if (keyCode === a) {
        setAddNewAddress(true);
        dispatch(getTabShip(facilityTab));
      }
    }
    if (keyCode === downarrow) {
      setDownPress(true);
    } else if (keyCode === uparrow) {
      dispatch(arrowUpIndex());
      setUpPress(true);
    } else if (keyCode === enter && !isButtonFocused) {
      setEnterPress(true);
    }
  }

  const handleShipAddressSelect = ({ item }) => {
    const data = {
      ...posFlowData,
      pickOrderQuantity: checkMixOrder
        ? pickOrderQuantity
        : [],
      pickQuantityArr: checkMixOrder
        ? pickQuantityArr
        : [],
      shiptoFacilities,
    };
    dispatch(setPosFlowData(data));
    setShipToByFacilityId(item?.buildingID);
    setShowShipToLocation(false);
    setShipToSelectId(item?.buildingID);
    dispatch(setShipToAddress(true));
    dispatch(resetNewAddress());
    // check that is same as pickup address or not 
    if (item?.buildingID !== loadCartDetails?.shipToByFacility?.facilityId) {
      dispatch(setToPickUp(false));
      dispatch(examptShipTolocationData(true));
      dispatch(
        shippingAddressSelectedData(true)
      );
      isGenericUser && dispatch(recipientInfoSwitchInfoData(!item?.isStorePickup))
    } else {
      dispatch(setToPickUp(true));
      dispatch(shippingAddressSelectedData(false));
      dispatch(examptShipTolocationData(false));
      isGenericUser && dispatch(recipientInfoSwitchInfoData(false))
    }
    setState({ ...state, right: false });
    (
      document.activeElement as HTMLElement
    ).blur();
    setPaymentMethodWrapper()

    isPaymentMethodWrapper && onCloseDrawer();
  }

  const switchToPickup = () => {
    const data = {
      ...posFlowData,
      pickOrderQuantity: checkMixOrder
        ? pickOrderQuantity
        : [],
      pickQuantityArr: checkMixOrder
        ? pickQuantityArr
        : [],
      shiptoFacilities: storePickedAddress,
    };
    dispatch(setPosFlowData(data));
    setShipToByFacilityId(
      storePickedAddress?.facilityId
    );
    setPickUpSelected(true);
    setState({ ...state, right: false });
    dispatch(setToPickUp(true));
    dispatch(setShipToAddress(true));
    dispatch(examptShipTolocationData(false));
    dispatch(shippingAddressSelectedData(false));
    isGenericUser && dispatch(recipientInfoSwitchInfoData(false))
    onCloseDrawer();
  }
  useEffect(() => {
    if (enterPress) {
      const shippingDataPress = searchString ? searchData[selectedIndex] : shippingData[selectedIndex];
      const buildingID = searchString ? searchData[selectedIndex]?.buildingID : shippingData[selectedIndex]?.buildingID
      const pickUP = searchString ? searchData[selectedIndex]?.isStorePickup : shippingData[selectedIndex]?.isStorePickup
      if (selectedIndex > -1) {
        selectedIndexShippingAddress(shippingDataPress);
      }
      if (
        showShipToLocation.isTrue &&
        showShipToLocation.index === selectedIndex && street
      ) {
        const data = {
          ...posFlowData,
          pickOrderQuantity: [],
          pickQuantityArr: [],
          finalQtyArrayInfoData: [],
          shiptoFacilities,
        };
        dispatch(setPosFlowData(data));
        setShipToByFacilityId(shipToSelectedId);
        // check that is same as pickup address or not 
        if (buildingID !== loadCartDetails?.shipToByFacility?.facilityId) {
          dispatch(setToPickUp(false));
          dispatch(examptShipTolocationData(true));
          dispatch(
            shippingAddressSelectedData(true)
          );
          dispatch(setShipToAddress(true));
          isGenericUser && dispatch(recipientInfoSwitchInfoData(!pickUP))
        } else {
          dispatch(setToPickUp(true));
          dispatch(shippingAddressSelectedData(false));
          dispatch(examptShipTolocationData(false));
          isGenericUser && dispatch(recipientInfoSwitchInfoData(false))
        }
        setPickUpSelected(false);
        setShowShipToLocation(false);
        setState({ ...state, right: false });
        setShipToSelectId(buildingID);
        setPaymentMethodWrapper();
        isPaymentMethodWrapper && onCloseDrawer();
      }
      setEnterPress(false);
    }
  }, [enterPress]);

  const selectedIndexShippingAddress = (item) => {
    if (!isLoading) {
      setShipToTemp("");
      dispatch(getShiptoFacilitiesRequest(item?.buildingID));
      setShowShipToLocation({
        index: selectedIndex,
        isTrue: true,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const addShippingAddressAPI = async (data) => {
    const {
      phoneNumber,
      firstName,
      lastName,
      companyName,
      companyAddress,
      city,
      zip,
      emailAddress,
    } = data;
    const uploadData = [];
    posFlowData?.addDocument?.length &&
      posFlowData?.addDocument.map((item: UploadedDocumentItemProp) => {
        const {
          file,
          description,
          typeId,
          documentSaveType,
          locationSaveType,
          uploadedBy,
          originalFile,
        } = item;
        if (originalFile) {
          const data = {
            fileName: file,
            description: description,
            typeId: typeId,
            documentSaveType: documentSaveType,
            locationSaveType: locationSaveType,
            uploadedBy: uploadedBy,
          };
          uploadData.push(data);
        }
      });
    setLoader(true);
    const filterStateCountry = stateName.split(", ");
    const state = filterStateCountry && filterStateCountry[0];

    const newNumber = phoneNumber && phoneNumber?.replace(/\s+/g, "");
    const areaCodeInfo = newNumber && newNumber?.substring(0, 3);
    const prefixInfo = newNumber && newNumber?.substring(3, 6);
    const lineNumInfo = newNumber && newNumber?.substring(6, 10);
    const shipToAddData = {
      buyerId: customer?.customerId || customer?.personId,
      stockWarehouseId: 0,
      facilityName: facilityName,
      facilityType: "11",
      firstName: firstName,
      lastName: lastName,
      title: facilityName,
      jobType: "Other",
      companyName: companyName,
      address: {
        street: companyAddress,
        extended: "",
        city: city,
        stateName: state,
        stateCode: "",
        countryName: "USA",
        //TODO: might need later
        // countryCode:'1',
        postalCode: zip,
        state: state,
        country: "USA",
      },
      email: emailAddress,
      phone: {
        phoneType: "DIRECT1",
        intCode: "1",
        areaCode: areaCodeInfo,
        prefix: prefixInfo,
        lineNum: lineNumInfo,
        extn: extCode,
      },
      uploadFiles: uploadData?.length ? uploadData : null,
    };
    const formData = new FormData();
    const formFiles = [];
    posFlowData?.addDocument?.length &&
      posFlowData?.addDocument?.map((item: UploadedDocumentItemProp) => {
        const {
          file,
          description,
          typeId,
          documentSaveType,
          locationSaveType,
          uploadedBy,
          originalFile,
        } = item;
        formFiles.push(originalFile);
        const fileUploadedData: any = {
          file: file,
          description: description,
          typeId: typeId,
          documentSaveType: documentSaveType,
          locationSaveType: locationSaveType,
          uploadedBy: uploadedBy,
        };
        return fileUploadedData;
      });
    const shipDataFormat = getStringifyData(getStringifyData(shipToAddData));
    formData.append("setNewShipToFacilityForCustomerRequest", shipDataFormat);
    posFlowData?.addDocument.forEach((item: UploadedDocumentItemProp) => {
      const { originalFile } = item;
      if (originalFile) {
        formData.append("formFiles", originalFile);
      }
    });

    dispatch(
      addShippingFacilitiesRequest(
        formData,
        customer?.customerId || customer?.personId
      )
    );
    setPickUpSelected(false);
    setShowShipToLocation(false);
    dispatch(setToPickUp(false));
    dispatch(setShipToAddress(true));
    dispatch(recipientInfoSwitchInfoData(true))
  };

  const handleSubmitTaxForm = () => dispatch(getTabShip(facilityDetails));
  const handleChangeModel = () => setTaxOpen(true);
  const handleClick = () => hiddenFileInput.current.click();

  useEffect(() => {
    const cartElement = document.getElementById("cartDrawerContent");
    if (addNewAddress) {
      !getStatesData?.length && dispatch(getStatesRequest());
      cartElement?.classList?.remove("cart-drawer-styling");
    } else {
      cartElement?.classList?.add("cart-drawer-styling");
    }
  }, [addNewAddress]);

  useEffect(() => {
    scrollOnContent();
  }, []);

  const checkMixOrder = posFlowData.cartProducts?.some(
    (item: QtyInStockProps) => {
      return item?.qtyInStock > 0;
    }
  );

  function shippingIcon() {
    return <PosImage src={shipping} alt="shipping-icon" />;
  }

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  const handleGoBack = () => {
    if (getTabShipping === Tabs.tabIndexFirst) {
      setAddNewAddress(false);
      dispatch(getTabShip(Tabs.tabIndexZero));
      setFacilityName("");
      detailsForm?.current?.resetForm();
    } else if (getTabShipping === Tabs.tabIndexTwo) {
      dispatch(getTabShip(facilityTab));
    } else if (getTabShipping === Tabs.tabIndexThree) {
      dispatch(getTabShip(facilityForm));
    } else if (getTabShipping === Tabs.tabIndexFour) {
      if (isGenericUser) {
        dispatch(getTabShip(facilityForm));
      } else {
        dispatch(getTabShip(addressTextForm));
      }
    }
  };

  const detailsForm = useRef<any>();
  const actualQty = posFlowData?.cartProducts.reduce(
    (a, b) => a + b.qtyOrdered,
    0
  );

  const isFullyPickedOrder =
    posFlowData?.cartProducts
      ?.map((item) => {
        return item?.pickedQtyWithLocation?.locations?.map((location) => {
          return location?.pickedQty;
        });
      })
      .flat()
      .filter((item) => item !== undefined)
      ?.reduce((total, obj) => Number(obj) + Number(total), 0) === actualQty;

  return (
    <Box role="presentation" className="h-100 modal-box">
      {addNewAddress &&
        (getTabShipping === Tabs.tabIndexFirst ||
          getTabShipping === Tabs.tabIndexTwo ||
          getTabShipping === Tabs.tabIndexThree ||
          getTabShipping === Tabs.tabIndexFour) ? (
        <PosIconButton
          className="back-next-btn-small btn responsive-back-btn pos-icon-back-btn"
          onClick={handleGoBack}
          tabIndex={-1}
        >
          <ArrowBackRoundedIcon />
        </PosIconButton>
      ) : (
        <PosIconButton
          className="back-next-btn-small btn responsive-back-btn pos-icon-back-btn"
          onClick={() => {
            onCloseDrawer();
          }}
          tabIndex={-1}
        >
          <ClearIcon />
        </PosIconButton>
      )}
      {!addNewAddress && (
        <Container
          className="modal-content-style modal-container-styling"
          ref={drawerRef}
          id="noScrollContent"
        >
          <div ref={shippingRef} className="topbar-cart m-0" />
          <main>
            <div className="drawer-static-header" id="drawer-header">
              <div>
                <div className="pricing-modal-header mb-0">
                  <div className="payment-option-heading">
                    <div>
                      <PosPageHeading heading="Delivery" grayHeading />
                      <h1 className="pricing-schedule-heading">
                        <PosPageHeading
                          heading="Select a shipping address"
                          blackHeading
                        />
                      </h1>
                    </div>
                    {shippingIcon()}
                  </div>
                </div>
                {posFlowData.cartProducts?.length &&
                  false &&
                  !isFullyPickedOrder ? (
                  <div className="set-price-text w-100">
                    <PosImage src={cautionIcon} alt="caution" />
                    <span className="set-price-text-style w-100">
                      Some items in the cart are backordered. Add or select a
                      shipping address to continue placing the order.
                    </span>
                  </div>
                ) : null}
                <div className="shipping-btns">
                  <div
                    onClick={() => {
                      const data = {
                        ...posFlowData,
                        addDocument: [],
                      };
                      dispatch(setPosFlowData(data));
                      setAddNewAddress(true);
                      dispatch(getTabShip(facilityTab));
                    }}
                    className="modal-select-default-btn ship-btn"
                  >
                    <div className="select-default-text">
                      <AddIcon />
                      <div className="default-schedule-heading">
                        <span className="int-18-reg-bold">
                          Add a New address
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="schedule-shortcut">A</span>
                    </div>
                  </div>
                  {shippingAddressSelected && (
                    <div
                      className="modal-select-default-btn ship-btn"
                      onClick={() => {
                        switchToPickup()
                      }}
                    >
                      <div className="select-default-text">
                        <div>
                          <ShoppingBagIcon />
                        </div>
                        <div className="default-schedule-heading">
                          <span className="int-18-reg-bold">
                            Switch to Pickup
                          </span>
                          <span className="int-14-reg-gray">
                            {
                              storePickedAddress?.recipient?.street
                            }
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className="schedule-shortcut">P</span>
                      </div>
                    </div>
                  )}
                  {/* )}  */}
                </div>
              </div>
              {shippingFacilities?.length > 0 && (
                <>
                  <div className="table-divider mb-4" />
                  <div className="search-with-close-input">
                    <div className="search-icon">
                      <SearchIcon />
                    </div>
                    <PosInput
                      variant="outlined"
                      id="outlined-basic"
                      className="double-bordered-input"
                      placeholder="Search Facility name"
                      value={searchString}
                      inputRef={searchBuildingRef}
                      onChange={(e) => {
                        setSearchString(e.target.value);
                        setSearchData(
                          shippingData.filter((data) =>
                            data.buildingName
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          )
                        );
                      }}
                    />
                    <Button
                      className="close-icon border-0 bg-transparent p-0 button-focus"
                      onClick={() => {
                        setSearchString("");
                      }}
                    >
                      <ClearIcon />
                    </Button>
                  </div>
                </>
              )}
            </div>
            {shippingFacilities?.length ? (
              <div
                className="facility-drawer drawer-content-scroll modal-subheading mb-0 mt-0 position-relative drawer-bottom-sec-styling shipping-scroll-data-styling"
                id="shippingScrollStyling"
              >
                <span className="modal-subheading mb-0 mt-0">
                  ALL FACILITIES
                </span>
                <div className={`all-schedules-names ${shippingFacilities?.length <= 4 ? "all-facilities-select-address" : null}`}>
                  {shippingData?.length && searchData?.length
                    ? searchData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          ref={rowRef[index]}
                          id={`rowRef${index}`}
                          className={
                            index === selectedIndex
                              ? "frequently-used-schedule frequently-used-schedule-index"
                              : "frequently-used-schedule"
                          }
                        >
                          <div
                            onClick={() => {
                              if (item?.buildingID !== posFlowData?.shiptoFacilities?.facilityId) {
                                setShipToTemp("");
                                dispatch(
                                  getShiptoFacilitiesRequest(
                                    item?.buildingID
                                  )
                                );
                                setShowShipToLocation({
                                  index: index,
                                  isTrue: true,
                                });
                              }
                            }}
                            className="ship-to-address-info"
                          >
                            <SelectShippingAddressNames
                              shippingData={item}
                              isLoading={isLoading}
                              recipient={recipient}
                              showShipToLocation={showShipToLocation}
                              dataIndex={index}
                              streetName={streetName}
                            />
                          </div>
                          {
                            item?.buildingID === posFlowData?.shiptoFacilities?.facilityId ?
                              <div className="address-selected-div">
                                <MaterialIcon icon="check" size="24" classes="address-selected-icon" />
                                <span className="address-selected-text">
                                  Selected
                                </span>
                              </div>
                              : null
                          }
                          {showShipToLocation.isTrue &&
                            showShipToLocation.index === index ? (
                            <PosButton
                              variant="contained"
                              color="primary"
                              disabled={!recipient?.street || isLoading}
                              className="btn ship-select-btn"
                              onClick={() => handleShipAddressSelect({ item })}
                            >
                              Select
                            </PosButton>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })
                    : shippingData?.length &&
                    !searchString &&
                    shippingData?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          ref={rowRef[index]}
                          id={`rowRef${index}`}
                          className={
                            index === selectedIndex
                              ? "frequently-used-schedule frequently-used-schedule-index"
                              : "frequently-used-schedule"
                          }
                        >
                          <div
                            onClick={() => {
                              if (item?.buildingID !== posFlowData?.shiptoFacilities?.facilityId) {
                                setShipToTemp("");
                                dispatch(
                                  getShiptoFacilitiesRequest(
                                    item?.buildingID
                                  )
                                );
                                setShowShipToLocation({
                                  index: index,
                                  isTrue: true,
                                });
                              }
                            }}
                            className="ship-to-address-info"
                          >
                            <SelectShippingAddressNames
                              shippingData={item}
                              isLoading={isLoading}
                              recipient={recipient}
                              showShipToLocation={showShipToLocation}
                              dataIndex={index}
                              streetName={streetName}
                            />
                          </div>
                          {
                            item?.buildingID === posFlowData?.shiptoFacilities?.facilityId ?
                              <div className="address-selected-div">
                                <MaterialIcon icon="check" size="24" classes="address-selected-icon" />
                                <span className="address-selected-text">
                                  Selected
                                </span>
                              </div>
                              : null
                          }
                          {
                            showShipToLocation.isTrue &&
                              showShipToLocation.index === index ? (
                              <PosButton
                                variant="contained"
                                color="primary"
                                disabled={!recipient?.street || isLoading}
                                className="btn ship-select-btn"
                                onClick={() => handleShipAddressSelect({ item })}
                              >
                                Select
                              </PosButton>
                            ) : (
                              <></>
                            )
                          }
                        </div>
                      );
                    })}
                </div>
                {shippingFacilities?.length > 4 ? <div className="back-to-top-section">
                  <Button
                    className="back-to-top-btn bg-transparent button-focus-class"
                    onClick={() => shippingRef.current.scrollIntoView()}
                  >
                    <ArrowUpwardIcon />
                    <span className="back-to-top-text">
                      Jump back to top
                    </span>
                  </Button>
                </div> : null}
              </div>
            ) : (
              !isLoading && (
                <span className="modal-subheading mb-0 mt-0 align-center">
                  No shipping address found
                </span>
              )
            )}
            {!openFromHeader ? <div className="delivery-select-address">
              <PosButton
                fontWeight
                onClick={() => setPaymentMethodWrapper()}
                variant="contained"
                color="primary"
                className="set-picking-info-btn btn w-100"
              >
                <div className="select-default-text mr-2">
                  <span className="int-18-reg-white">
                    {continueToPayment}
                  </span>
                </div>
              </PosButton>
            </div> : null}
          </main>
        </Container>
      )
      }
      <div>
        {addNewAddress && (
          <div className="progress-bar-new-address">
            <div className="progress-bar-step progress-fill" />
            <div
              className={`progress-fill
                ${getTabShipping === facilityForm ||
                  getTabShipping === facilityDetails
                  || getTabShipping === addressTextForm
                  ? "fill-progress-bar"
                  : "progress-bar-steps"
                }
                `}
            />
            <div
              className={`progress-fill
                          ${getTabShipping === facilityDetails || getTabShipping === addressTextForm
                  ? "fill-progress-bar"
                  : "progress-bar-steps"
                }
              `}
            />
            <div
              className={`progress-fill
              ${getTabShipping === facilityDetails
                  ? "fill-progress-bar"
                  : "progress-bar-steps"
                }
              `}
            />
          </div>
        )}
        {addNewAddress && getTabShipping === facilityTab && (
          <Container>
            <div className="pricing-modal-header-address">
              <div className="payment-option-heading mb-5">
                <div>
                  <PosPageHeading
                    heading="Add a New Address"
                    grayHeading
                    className="mb-1"
                  />
                  <PosPageHeading
                    heading="Step 1: Facility Details"
                    blackHeading
                  />
                </div>
                {shippingIcon()}
              </div>
            </div>
            <div className="facility-box mt-5">
              <span className="form-field-title">Facility Name</span>
              <PosInput
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                className="mb-0 mt-3 search-input-focus"
                value={facilityName}
                onChange={(e) => {
                  if (
                    !e.target.value.includes("  ") &&
                    e.target.value.charAt(0) !== " "
                  ) {
                    setFacilityName(e.target.value);
                    setStep0Errors(false);
                  }
                }}
              />
            </div>
            {step0Errors && (
              <p className="validation-error-text mt-2">
                Please select a valid facility name.
              </p>
            )}
            {step1Errors && (
              <p className="validation-error-text">
                Please select a valid facility type.
              </p>
            )}
            <div className="btns-container-discard-save payment-drawer-btn-spacing flex-row-reverse justify-start">
              <PosButton
                variant="contained"
                color="primary"
                className="btn-savechanges new-address-continue-btn add-ship-address-continue-btn btn"
                onClick={() => {
                  if (facilityName === "" || facilityType === "") {
                    if (facilityName === "") {
                      setStep0Errors(true);
                    }
                    if (facilityType === "") {
                      setStep1Errors(true);
                    }
                  } else {
                    dispatch(getTabShip(facilityForm));
                  }
                }}
              >
                Continue
              </PosButton>
              <PosButton
                variant="contained"
                className="btn-discard new-address-cancel-btn btn"
                onClick={() => {
                  setAddNewAddress(false);
                  dispatch(getTabShip(Tabs.tabIndexZero));
                  setFacilityName("");
                  detailsForm?.current?.resetForm();
                }}
                startIcon={<ClearIcon />}
              >
                Cancel
              </PosButton>
            </div>
            <></>
          </Container>
        )}
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          title: "",
          jobDescription: "",
          state: "",
          companyName: "",
          companyAddress: "",
          city: "",
          zip: null,
          emailAddress: "",
          phoneNumber: null,
          countryCode: 1,
        }}
        validationSchema={AddAddressSchema}
        enableReinitialize
        innerRef={detailsForm}
        onSubmit={() => {
          if (!stateName) {
            setStateNameError(true);
          } else {
            if (isGenericUser) {
              dispatch(getTabShip(facilityDetails));
            } else {
              dispatch(getTabShip(addressTextForm));
            }
          }
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => {
          const {
            firstName,
            lastName,
            companyName,
            companyAddress,
            city,
            zip,
            emailAddress,
            phoneNumber,
            countryCode,
          } = values;
          return (
            <>
              <div>
                {addNewAddress && getTabShipping === facilityForm && (
                  <Container>
                    <div className="pricing-modal-header-address mb-5">
                      <div className="payment-option-heading">
                        <div>
                          <PosPageHeading
                            heading="Add a New Address"
                            grayHeading
                            className="mb-1"
                          />
                          <PosPageHeading
                            heading="Step 2: Shipping"
                            blackHeading
                          />
                        </div>
                        {shippingIcon()}
                      </div>
                    </div>
                    <form className="mt-5 pb-5">
                      <span className="int-20-reg-bold">
                        Personal Information
                      </span>
                      <div className="facility-box form-field-spacing">
                        <span className="form-field-title">First Name</span>
                        <PosInput
                          variant="filled"
                          id="filled-basic"
                          InputProps={{ disableUnderline: true }}
                          className="mb-0 mt-2 search-input-focus"
                          name="firstName"
                          onChange={(e) => {
                            if (
                              !e.target.value.includes("  ") &&
                              e.target.value.charAt(0) !== " "
                            ) {
                              setFieldValue("firstName", e.target.value);
                            }
                          }}
                          value={firstName}
                        />
                      </div>
                      {errors.firstName && touched.firstName && (
                        <p className="validation-error-text mt-2">
                          {errors.firstName}
                        </p>
                      )}
                      <div className="facility-box form-field-spacing">
                        <span className="form-field-title">Last Name</span>
                        <PosInput
                          variant="filled"
                          id="filled-basic"
                          InputProps={{ disableUnderline: true }}
                          className="mb-0 mt-2 search-input-focus"
                          name="lastName"
                          onChange={(e) => {
                            if (
                              !e.target.value.includes("  ") &&
                              e.target.value.charAt(0) !== " "
                            ) {
                              setFieldValue("lastName", e.target.value);
                            }
                          }}
                          value={lastName}
                        />
                      </div>
                      {errors.lastName && touched.lastName && (
                        <p className="validation-error-text mt-2">
                          {errors.lastName}
                        </p>
                      )}
                      <div className="slim-liner mb-4 form-field-spacing" />
                      <span className="int-20-reg-bold">
                        Company Information
                      </span>
                      <div className="facility-box mt-4">
                        <span className="form-field-title">Company Name</span>
                        <PosInput
                          variant="filled"
                          id="filled-basic"
                          InputProps={{ disableUnderline: true }}
                          className="mb-0 mt-2 search-input-focus"
                          name="companyName"
                          onChange={(e) => {
                            if (
                              !e.target.value.includes("  ") &&
                              e.target.value.charAt(0) !== " "
                            ) {
                              setFieldValue("companyName", e.target.value);
                            }
                          }}
                          value={companyName}
                        />
                        {errors.companyName && touched.companyName && (
                          <p className="validation-error-text mt-2">
                            {errors.companyName}
                          </p>
                        )}
                      </div>
                      <div className="facility-box form-field-spacing">
                        <span className="form-field-title">
                          Company Address
                        </span>
                        <PosInput
                          variant="filled"
                          id="filled-basic"
                          InputProps={{ disableUnderline: true }}
                          className="mb-0 mt-2 search-input-focus"
                          name="companyAddress"
                          onChange={(e) => {
                            if (
                              !e.target.value.includes("  ") &&
                              e.target.value.charAt(0) !== " "
                            ) {
                              setFieldValue("companyAddress", e.target.value);
                            }
                          }}
                          value={companyAddress}
                        />
                        {errors.companyAddress && touched.companyAddress && (
                          <p className="validation-error-text mt-2">
                            {errors.companyAddress}
                          </p>
                        )}
                      </div>
                      <div className="facility-box form-field-spacing">
                        <span className="form-field-title">City</span>
                        <PosInput
                          variant="filled"
                          id="filled-basic"
                          InputProps={{ disableUnderline: true }}
                          className="mb-0 mt-2 search-input-focus"
                          name="city"
                          onChange={(e) => {
                            if (
                              !e.target.value.includes("  ") &&
                              e.target.value.charAt(0) !== " "
                            ) {
                              setFieldValue("city", e.target.value);
                            }
                          }}
                          value={city}
                        />
                        {errors.city && touched.city && (
                          <p className="validation-error-text mt-2">
                            {errors.city}
                          </p>
                        )}
                      </div>
                      <div className="facility-box form-field-spacing arrow-black">
                        <span className="form-field-title mb-2">State</span>
                        <PosSelect
                          // isSearch : Removing Search as not needed for this dropdown
                          id={"state"}
                          name="state"
                          className=" tabDropdown"
                          defaultValue={stateName}
                          value={stateName}
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            handleChange("state");
                            setStateName(e.target.value);
                          }}
                          options={
                            statesData?.length &&
                            statesData?.map(({ stateName }: StateDataProps) => {
                              return {
                                label: stateName,
                                value: stateName,
                              };
                            })
                          }
                        />
                        {stateName === "" && stateNameError && (
                          <p className="validation-error-text mt-2">
                            Please select a state.
                          </p>
                        )}
                      </div>
                      <div className="facility-box form-field-spacing">
                        <span className="form-field-title">Zip</span>
                        <PosInput
                          type="string"
                          variant="filled"
                          id="filled-basic"
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ maxLength: 6 }}
                          maxLength={5}
                          className="mb-0 mt-2 search-input-focus"
                          name="zip"
                          onKeyPress={(e) => blockInvalidChar(e)}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              (zipRegex.test(e.target.value) && changestringVal)
                            ) {
                              setFieldValue("zip", e.target.value);
                            }
                          }}
                          value={values.zip}
                          onWheel={(e) => {
                            e.target.blur();
                          }}
                        />
                        <ErrorMessage name="zip" />
                      </div>
                      <div className="facility-box form-field-spacing">
                        <span className="form-field-title">Email Address</span>
                        <PosInput
                          variant="filled"
                          id="filled-basic"
                          InputProps={{ disableUnderline: true }}
                          className="mb-0 mt-2 search-input-focus"
                          type="email"
                          name="emailAddress"
                          onChange={(e) => {
                            if (
                              !e.target.value.includes("  ") &&
                              e.target.value.charAt(0) !== " "
                            ) {
                              setFieldValue("emailAddress", e.target.value);
                            }
                          }}
                          value={emailAddress}
                        />
                        {errors.emailAddress && touched.emailAddress && (
                          <p className="validation-error-text mt-2">
                            {errors.emailAddress}
                          </p>
                        )}
                      </div>
                      <div className="facility-box form-field-spacing">
                        <div className="phone-number-title">
                          <span className="form-field-title">Phone Number</span>
                          <div className="phone-ext-title">
                            <span>Ext</span>
                            <span className="phone-optional-head ml-3">
                              (Optional)
                            </span>
                          </div>
                        </div>
                        <div className="facilit-phone-number">
                          <PosInput
                            variant="filled"
                            id="filled-basic"
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ maxLength: 3 }}
                            disabled
                            className="phone-country-code-input mb-0 mt-2 search-input-focus"
                            value={values.countryCode}
                            onChange={(e) => {
                              const re = /^[0-9]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setFieldValue("countryCode", e.target.value);
                              }
                            }}
                          />
                          <div className="masked-phone-number">
                            <MaskInput
                              mask="999 999 9999"
                              isClassName="masked-phone-number-input mb-0 tabInput-mobile"
                              id={"filled-basic"}
                              name={"phoneNumber"}
                              alwaysShowMask={true}
                              onChange={(e) => {
                                setFieldValue("phoneNumber", e.target.value);
                              }}
                              onKeyPress={(e) => blockInvalidCharForPhone(e)}
                              value={values.phoneNumber || ""}
                            />
                          </div>
                          <PosInput
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            id={"extCode"}
                            className="phone-ext-input mb-0 mt-2 search-input-focus"
                            value={extCode}
                            inputProps={{ maxLength: 4 }}
                            onChange={(e) => {
                              const re = /^[0-9]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setExtCode(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <ErrorMessage name="countryCode" />
                        <ErrorMessage name="phoneNumber" />
                      </div>
                      <div className="btns-container-discard-save mt-5 flex-row-reverse justify-start">
                        <PosButton
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setStateNameError(true);
                            handleSubmit();
                          }}
                          className="preview-shipping-btn preview-address-btn btn-savechanges new-address-continue-btn add-ship-address-continue-btn btn"
                          type="submit"
                        >
                          Continue
                        </PosButton>
                        <PosButton
                          variant="contained"
                          className="btn-discard new-address-cancel-btn btn"
                          onClick={() => dispatch(getTabShip(facilityTab))}
                          startIcon={<ArrowBackRoundedIcon />}
                        >
                          Go Back
                        </PosButton>
                      </div>
                    </form>
                  </Container>
                )}
              </div>
              <div>
                {!isGenericUser &&
                  addNewAddress &&
                  getTabShipping === addressTextForm && (
                    <Container>
                      <div className="pricing-modal-header-address">
                        <div className="payment-option-heading">
                          <div>
                            <PosPageHeading
                              heading="Add a New Address"
                              grayHeading
                              className="mb-1"
                            />
                            <PosPageHeading
                              heading="Step 3: Tax Documents"
                              blackHeading
                            />
                          </div>
                          {shippingIcon()}
                        </div>
                      </div>
                      <div className="mt-4 mb-4">
                        <span className="int-20-reg-bold">
                          Attach documents if this location is tax-exempt.
                        </span>
                      </div>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <PosButton
                          variant="contained"
                          color="primary"
                          className="btn-add-customer tax-file-upload-btn btn tax-file-add-doc-btn"
                          type="button"
                          onClick={() => {
                            setOpenLocationModel(true);
                          }}
                          fontWeight
                        >
                          <PosImage
                            src={whiteUploadIcon}
                            alt="upload-icon"
                            className="icn-btn"
                          />
                          Add Document
                        </PosButton>
                      </div>
                      {posFlowData?.addDocument?.length ? (
                        <div className="p-1 mt-2">
                          <span>
                            {posFlowData?.addDocument?.length}{" "}
                            {`${posFlowData?.addDocument?.length === 1
                              ? "Document"
                              : "Documents"
                              } added`}
                          </span>
                          <PosButton
                            isClassName="tax-chnage-btn change-doc-btn-focus"
                            type="submit"
                            onClick={handleChangeModel}
                          >
                            Change
                          </PosButton>
                        </div>
                      ) : null}

                      <div className="slim-liner mtb-10" />
                      <div className="btns-container-discard-save mt-5 flex-row-reverse justify-start">
                        <PosButton
                          variant="contained"
                          color="primary"
                          disabled={loader}
                          className="btn-savechanges new-address-continue-btn new-address-preview-btn btn"
                          onClick={() => handleSubmitTaxForm()}
                        >
                          <span>Preview Address</span>
                        </PosButton>
                        <PosButton
                          variant="contained"
                          className="btn-discard new-address-go-back-btn btn"
                          onClick={() => dispatch(getTabShip(facilityForm))}
                          startIcon={<ArrowBackRoundedIcon />}
                        >
                          Go Back
                        </PosButton>

                      </div>
                      <AttachedDocumentModal
                        taxOpen={taxOpen}
                        setTaxOpen={setTaxOpen}
                        handleClick={handleClick}
                        documentBy={documentByModule.ship}
                      />
                    </Container>
                  )}
              </div>
              <div>
                {addNewAddress && getTabShipping === facilityDetails && (
                  <Container>
                    <div className="pricing-modal-header-address">
                      <div className="payment-option-heading">
                        <div>
                          <PosPageHeading
                            heading="Add a New Address"
                            grayHeading
                            className="mb-1"
                          />
                          <PosPageHeading
                            heading={
                              isGenericUser
                                ? "Step 3: Confirmation"
                                : "Step 4: Confirmation"
                            }
                            blackHeading
                          />
                        </div>
                        {shippingIcon()}
                      </div>
                    </div>
                    <div className="mt-4 mb-4">
                      <span className="int-20-reg-bold">
                        Please verify the details to save the new address or
                        Edit to make changes.
                      </span>
                    </div>
                    <div className="facility-box">
                      <span className="int-18-reg-bold">{facilityName}</span>
                    </div>
                    <div className="slim-liner mtb-10" />
                    <div className="facility-box">
                      <span className="int-18-reg-bold">
                        {firstName} {lastName}
                      </span>
                    </div>
                    <div className="slim-liner mtb-10" />
                    <div className="facility-box">
                      <span className="int-18-reg-bold">{companyName}</span>
                      <span className=" int-16-reg">{companyAddress}</span>
                      <span className=" int-16-reg">
                        {city}, {stateName}
                      </span>
                      <span className=" int-16-reg">{zip}</span>
                    </div>
                    <div className="slim-liner mtb-10" />
                    <div className="facility-box">
                      <span className="confirmation-title-text mb-1 label-style">
                        EMAIL ADDRESS
                      </span>
                      <span className="int-18-reg-bold ml-3">
                        {emailAddress}
                      </span>
                    </div>
                    <div className="slim-liner mtb-10" />
                    <div className="facility-box">
                      <span className="confirmation-title-text mb-1 label-style">
                        PHONE NUMBER
                      </span>
                      <span className="int-18-reg-bold">
                        {phoneNumberFormatNew(
                          countryCode.toString(),
                          phoneNumber?.substring(0, 3),
                          phoneNumber?.substring(4, 7),
                          phoneNumber?.substring(8, 12),
                          extCode,
                          false
                        )}
                      </span>
                    </div>
                    {posFlowData?.addDocument?.length ? (
                      <>
                        <div className="slim-liner mtb-10"></div>
                        <div className="facility-box">
                          <span className="confirmation-title-text mb-1 label-style">
                            DOCUMENTS
                          </span>
                          <span className="int-18-reg-bold">
                            <span>
                              {posFlowData?.addDocument?.length}{" "}
                              {`${posFlowData?.addDocument?.length === 1
                                ? "Document"
                                : "Documents"
                                } added`}
                            </span>

                            <PosButton
                              isClassName="tax-chnage-btn change-doc-btn-focus"
                              type="submit"
                              onClick={handleChangeModel}
                            >
                              Change
                            </PosButton>
                          </span>
                        </div>
                      </>
                    ) : null}
                    <div className="slim-liner mtb-10" />
                    <div className="btns-container-discard-save mt-5 flex-row-reverse justify-start">
                      <PosButton
                        variant="contained"
                        color="primary"
                        disabled={loader}
                        startIcon={<CheckIcon />}
                        className="btn-savechanges new-address-continue-btn new-address-preview-btn btn"
                        onClick={() => {
                          addShippingAddressAPI(values);
                        }}
                      >
                        Confirm Address
                      </PosButton>
                      <PosButton
                        variant="contained"
                        startIcon={<ArrowBackRoundedIcon />}
                        className="btn-discard new-address-cancel-btn btn new-address-go-back-btn"
                        onClick={() => {
                          if (isGenericUser) {
                            dispatch(getTabShip(facilityForm));
                          } else {
                            dispatch(getTabShip(addressTextForm));
                          }
                        }}
                      >
                        Edit
                      </PosButton>
                    </div>
                    <AttachedDocumentModal
                      taxOpen={taxOpen}
                      setTaxOpen={setTaxOpen}
                      handleClick={handleClick}
                      documentBy={documentByModule.ship}
                    />
                  </Container>
                )}
              </div>
            </>
          );
        }}
      </Formik>

      {
        openLocationModel ? (
          <AddDocumentModal
            locationDocument
            openModel={openLocationModel}
            setOpenModel={setOpenLocationModel}
            taxExemption={false}
            documentBy={documentByModule.ship}
          />
        ) : null
      }
    </Box >
  );
};

export default SelectShippingAddress;
